import React from 'react';
import SidebarItem from '../../components/Sidebar/SidebarItem';

const ParagraphsWithTitle = ({title, paragraphs}) => {
    return (
        <div className="row sp-bottom-wt">
            <div className="col-md-3 col-lg-2">
                <div className="mt-sm-90 mt-md-100">
                    <SidebarItem title={title} classes={'single-sidebar-item-wrap'}></SidebarItem>
                </div>
            </div>
            <div className="m-auto col-md-9 col-lg-8">
                <div className="text-justify">
                    {
                        paragraphs.map((para, index) => (                            
                            <p key={index} dangerouslySetInnerHTML={{__html: para}}/>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default ParagraphsWithTitle;