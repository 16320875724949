import React,{Fragment} from 'react';
import { NavLink, useParams } from 'react-router-dom';
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ServiceContentWrap from "./ServiceContentWrap";
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";

import ServicesData from "../../data/Services/services";

const ServiceDetails = () => {
    let { serviceID } = useParams();
    //const serviceID = new URLSearchParams(window.location.search).get("id");
    const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));
    const currentService = ServicesData.indexOf(service);
    let prevService;
    let nextService;
    currentService === 0 ? prevService = ServicesData[currentService] : prevService = ServicesData[currentService-1];
    currentService+1 === ServicesData.length ? nextService = ServicesData[currentService] : nextService = ServicesData[currentService+1];

    return (
        <Fragment>
            <PageHeader
                bgImg={require('../../assets/img/page-header-services.jpeg')}
                title={service.title}
                content={service.shortDesc}
            />

            <PageWrapper classes="sm-y service-details-wrapper">
                <ServiceContentWrap
                    service={service}
                    totalService={ServicesData.length}
                    nextService={nextService}
                    prevService={prevService}
                />

                <Sidebar classes={'col-lg-4'}>
                    <SidebarItem title="Services">
                        <List classes="service-list">
                            {
                                ServicesData.map(serviceItem=>(
                                    <LI key={serviceItem.id}>
                                        <NavLink to={`${process.env.PUBLIC_URL + `/service/${serviceItem.id}`}`} exact activeClassName="active">{serviceItem.title}</NavLink>
                                    </LI>
                                ))
                            }
                        </List>
                    </SidebarItem>
                </Sidebar>
            </PageWrapper>
        </Fragment>
    );
};

export default ServiceDetails;