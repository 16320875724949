import React,{Fragment} from 'react';
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import ConsentModal from '../components/ConsentModal';
import MobileMenu from "../components/MobileMenu";

const PageServiceDetails = () => {
    return (
        <Fragment>
            <Header/>
            <ServiceDetails/>
            <CallToAction/>
            <Footer/>
            <ConsentModal/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageServiceDetails;