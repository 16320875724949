import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import ConsentModal from '../components/ConsentModal';
import MobileMenu from "../components/MobileMenu";
import Features from "../components/Features";

const PageAbout = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header-company.jpeg')}
                title="ABOUT US"
                content="NRBA LLP always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
                classForColor="off-white"
            />
            <About/>
            <Features classes={'sm-y'}/>
            {/* <Funfact classes="sp-top"/> */}
            <CallToAction/>
            <Footer/>
            <ConsentModal/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageAbout;