import React, {Fragment} from 'react';

import Header from '../components/Header'
import Slider from '../components/Slider/home-one'
import About from '../components/About/home-one'
import Features from '../components/Features'
import Services from '../components/Services'
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ConsentModal from '../components/ConsentModal';

const HomeOne = () => {
    return (
        <Fragment>
            <Header/>
            <Slider/>
            <About/>
            <Features classes="sp-top"/>
            <Services classes="sm-top-wt sp-bottom-wt"/>
            <CallToAction/>
            <Footer/>
            <MobileMenu/>
            <ConsentModal/>
        </Fragment>
    );
};

export default HomeOne;