import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';

function CustomModal ({title, children, footer, closable, open}) {
    const [init, setInit] = useState(false)
    const [modal, setModal] = useState(null)
    useEffect(() => {
        if(!init) {
            const modalElement = document.getElementById('myModal');
            const mod = new Modal(modalElement, {
                backdrop: true,
                keyboard: true,
                focus: true
            })
            setModal(mod);
            setInit(true);
        }
    })
    useEffect(() => {
        if(init) {
            if(open)
                modal.show();
            else {
                modal.hide();
            }
        }
    }, [open, init]);
    return (
        <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        {closable &&
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomModal;
