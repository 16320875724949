import React,{Fragment} from 'react';
import Header from "../components/Header";
import TeamDetailsPage from "../templates/TeamDetails";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import ConsentModal from '../components/ConsentModal';
import MobileMenu from "../components/MobileMenu";

const PageTeamDetails = () => {
    return (
        <Fragment>
            <Header/>
            <TeamDetailsPage/>
            <CallToAction/>
            <Footer/>
            <ConsentModal/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageTeamDetails;