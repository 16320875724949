import React, { useState, useEffect } from 'react';
import Modal from '../UI/Modal';

const CONSENTVALUEKEY = "consentValue"

function ConsentModal() {
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [consentValue, setConsentValue] = useState(false);

    useEffect(() => {
        const consentV = localStorage.getItem(CONSENTVALUEKEY);
        if(consentV == "true")
            setConsentValue(true);
        else
            setOpen(true);
    }, []);
    useEffect(() => {
        setDisabled(!consentValue);
        if(consentValue)
            localStorage.setItem(CONSENTVALUEKEY, true);
    }, [consentValue]);
    const Footer = <div className="d-block w-100">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" value={consentValue} onChange={(e) => setConsentValue(e.target.checked)} id="flexCheckDefault"/>
            <label className="form-check-label" htmlFor="flexCheckDefault">
                I have read and understood above and I agree with the terms of usage of this website
            </label>
        </div>
        <button className="btn btn-primary" disabled={disabled} onClick={() => setOpen(false)}>Proceed</button>
    </div>;
    return (
        <Modal title={'Disclaimer'}
            closable={false}
            footer={Footer}
            open={open}
        >
            <p>The Institute of Chartered Accountants of India does not permit advertisement or solicitation by Chartered Accountants in any form or manner. By accessing this website, www.nrballp.com, you acknowledge and confirm that you are seeking information relating to N R B A & Co LLP on your own accord and that there has been no form of solicitation, advertisement or inducement by N R B A & Co LLP or its partners or employees.</p>
            <p>The contents of this website are for informational purposes only and should not be interpreted as soliciting or advertising. No information provided on this website should be used or construed as substitute of professional advice. N R B A & Co LLP shall not be liable for consequences of any action taken by relying on the information provided on this website. It is recommended that the readers should take professional advice before acting on the same.</p>
            <p>The contents of this website are the intellectual property of N R B A & Co LLP.</p>
        </Modal>

    );
};

export default ConsentModal;