import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/page";
import TeamMember from '../templates/Team'
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import ConsentModal from '../components/ConsentModal';
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/about.jpg'

const PageTeam = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header-team.jpeg')}
                title="KNOW ABOUT NRBA LLP TEAM"
                content="NRBA LLP always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
            />
            <About
                title={'Our Team'}
                heading="Meet Our <br/> Expert Member"
                thumb={ServiceThumb}
                content="<b>NRBA LLP</b> always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly. We are one of the best teams that is always ready to help you."
            />
            <TeamMember/>
            {/* <Funfact classes="sp-top"/> */}
            <CallToAction/>
            <Footer/>
            <ConsentModal/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageTeam;