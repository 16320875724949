import React, { useMemo, useState } from 'react';
import FormInput from "../../components/UI/Input";

const From = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    const body = useMemo(() => {
        const newLine = '%0D%0A'
        return `FirstName: ${firstName}${newLine}LastName: ${lastName}${newLine}Email: ${email}${newLine}Phone: ${phone}${newLine}Message: ${message}`
                        
    }, [firstName, lastName, email, phone, message])
    const sendMail = (event) => {
        event.preventDefault();
        setTimeout(() => {
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setMessage("");
        }, 200);
        window.location.href = `mailto:connect@nrballp.com?subject=Inquiry&body=${body}`;
    }
    return (
        <div className="contact-form-wrap">
            <form id="contact-form" onSubmit={e => sendMail(e)}>
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'first_name'}
                            placeholder={'First Name *'}
                            value={firstName}
                            required={true}
                            onChange={setFirstName}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'last_name'}
                            placeholder={'Last Name *'}
                            value={lastName}
                            required={true}
                            onChange={setLastName}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name={'email_address'}
                            placeholder={'Email address *'}
                            value={email}
                            required={true}
                            onChange={setEmail}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'phone_no'}
                            placeholder={'Phone No'}
                            value={phone}
                            required={false}
                            onChange={setPhone}
                        />
                    </div>

                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}
                            name={'con_message'}
                            placeholder={'Write Your Message *'}
                            value={message}
                            required={true}
                            onChange={setMessage}
                        />

                        <FormInput
                            type={'submit'}
                            tag={'button'}
                            classes={'btn-outline'}
                        />

                        <div className="form-message"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default From;