import React from 'react';
import UseFulLinksData from '../../data/UsefulLinks/usefulLinks';

function UsefulLinks() {
    return (
        <div className="container sp-top table">
            {
                UseFulLinksData.map((content, index) => (
                    <div className="row">
                        <div className="col border">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    {content.area}
                                </div>
                                <div  className="col-12 col-md-8">
                                    <div className="row">
                                        <div className="col-5">
                                            {content.descriptions.map(desc => 
                                                <>
                                                    {desc}
                                                    <br/>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-7">
                                            {content.links.map(link => 
                                                <>
                                                    <a href={link} target="_blank" rel='noopener noreferrer'>
                                                        {link}
                                                    </a>
                                                    <br/>
                                                </>
                                            )}                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default UsefulLinks;