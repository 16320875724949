import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import UsefulLinks from "../components/UsefulLinks";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import ConsentModal from '../components/ConsentModal';
import MobileMenu from "../components/MobileMenu";

const PageUsefulLinks = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header-useful-links.jpeg')}
                title="USEFUL LINKS"
                content="Some useful links so that you don't have to search them."
            />
            <UsefulLinks/>
            <CallToAction/>
            <Footer/>
            <ConsentModal/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageUsefulLinks;