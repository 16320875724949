import React from 'react';
import ParagraphsWithTitle from '../../templates/ParagraphsWithTitle';

import Abouts from '../../data/About/about.json';

function About() {
    return (
        <div className="container sp-top">
            {
                Abouts.map((content, index) => (
                    <ParagraphsWithTitle key={index} title={content.title} paragraphs={content.details} />
                ))
            }
        </div>
    );
};

export default About;