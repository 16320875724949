import React,{Fragment} from 'react';
import socialNetworks from '../../data/SocialNetworks/socials'
import skype from '../../data/SocialNetworks/skype'
import Map from './Map'

const ContactInfo = ({address}) => {
    const skypeClick = (skypeId) => {
        window.location = `skype:${skypeId}?chat`
    }
    return (
        <Fragment>
            <div className="widget-item m-0">
                <address>
                    <span dangerouslySetInnerHTML={{__html: address}}/>
                </address>                
            </div>
            <div className="row">
                <div className="col">
                    <Map />
                </div>
            </div>
            <div className="member-social-icons mt-30">
                {
                    socialNetworks.map(social=>(
                        <a key={social.id} href={`https://${social.networkName}.com/${social.username}`} target="_blank" rel="noopener noreferrer">
                            <i className={`fa fa-${social.networkName}`}/>
                        </a>
                    ))
                }
            </div>
            <div className="mt-10">
                Let’s have Chat on Skype<br/>
                {
                    skype.map((id, index) => (
                        <span key={index}><a role="button" onClick={() => skypeClick(id)}><u>{id}</u></a><br/></span>
                    ))
                }
            </div>
        </Fragment>
    );
};

export default ContactInfo;