import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import socialNetworks from '../../data/SocialNetworks/socials'
import ContactData from '../../data/Contact/contact'

function Footer() {
    const MailtoClick = (e, mailto) => {
        window.location.href = `mailto:${mailto}`;
        e.preventDefault();
    };
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <Widget title="N R B A & CO LLP">
                                    </Widget>
                                </Link>
                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} NRBA LLP Ltd. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Information">
                            <List classes="widget-list">
                                <LI><Link to={`${process.env.PUBLIC_URL + "/about"}`}>Our company</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/contact"}`}>Contact us</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our services</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/useful-links"}`}>Useful links</Link></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <LI><Link to="#" onClick={(e) => MailtoClick(e, "connect@nrballp.com")} target={'_blank'}>Mail</Link></LI>
                                {
                                    socialNetworks.map(social=> (
                                        <LI key={social.id}><Link to={{pathname: `https://${social.networkName}.com/${social.username}`}} target={'_blank'}>{social.networkName}</Link></LI>
                                ))
                            }
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                {
                                    ContactData.address.map((addLine, index) => (
                                        <span key={index}>{addLine} <br/></span>
                                    ))
                                }
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;