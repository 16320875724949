import React from 'react';
import map from "../../data/Contact/map"

const Map = () => {
    return (
        <div className="w-100">
            <iframe width="316" height="200" id="gmap_canvas" src={map.googleMapLink}></iframe>
        </div>
    )
};

export default Map;